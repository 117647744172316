import React from "react";
import { Box, Link, Typography } from "@mui/material";
import "./css/Map.css";
import HomeIcon from "@mui/icons-material/Home";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";

const Footer = () => {
  return (
    <Box
      mt={4}
      mx={"auto"}
      sx={{
        backgroundColor: "secondary.main",
      }}
    >
      <Box p={4} sx={{ maxWidth: "xl", mx: "auto" }}>
        <Typography variant="h6" color="white">
          CONSULTORIO DENTAL MYA
        </Typography>
        <br />
        <Typography
          variant="body1"
          color="white"
          sx={{ display: "flex", my: 0.25, alignContent: "center" }}
        >
          <HomeIcon fontSize="small" sx={{ mr: 1, mt: "1px" }} />
          Puerto Escondido #310, San Jerónimo Chicahualco, Metepec, México. C.P.
          52170
        </Typography>
        <Typography
          variant="body1"
          color="white"
          sx={{ display: "flex", my: 0.25, alignContent: "center" }}
        >
          <EmailIcon fontSize="small" sx={{ mr: 1, mt: "1px" }} />
          Correo Electrónico:
          <Link
            underline="none"
            href="mailto:info@cdmya.com"
            color="primary.light"
            ml={1}
          >
            info@cdmya.com
          </Link>
        </Typography>
        <Typography
          variant="body1"
          color="white"
          sx={{ display: "flex", my: 0.25, alignContent: "center" }}
        >
          <PhoneIcon fontSize="small" sx={{ mr: 1, mt: "1px" }} />
          Teléfono:
          <Link
            underline="none"
            href="tel:7229322216"
            color="primary.light"
            ml={1}
          >
            728-135-0247
          </Link>
        </Typography>
      </Box>
      <Box
        display="flex"
        maxWidth={1}
        sx={{
          mx: "auto",
        }}
      >
        <img alt="Logo Footer" src="/static/Icono.svg" className="logoFooter" />
      </Box>
    </Box>
  );
};

export default Footer;
