import React from "react";
import { Grid, Card, Typography } from "@mui/material";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import ServiciosMain from "./ServiciosMain";
import Box from "@mui/material/Box";
import Map from "./Map";

const Inicio = () => {
  return (
    <>
      <Grid
        container
        spacing={2}
        alignItems="center"
        justifyContent="center"
        direction="column"
        justify="center"
      >
        <Grid item xs={12} md={6} mb={2} mt={3}>
          <Card
            variant="outlined"
            sx={{
              width: "100%",
              maxWidth: "l",
              display: { s: "none", md: "flex" },
            }}
          >
            <CardMedia
              component="img"
              image="/static/inicio.png"
              alt="Joven sonriendo"
              sx={{
                height: { xs: "500px", sm: "500px", lg: "650px", xl: "700px" },
              }}
            />
            <CardContent
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h2"
                component="h1"
                color="primary"
                align="center"
                p={2}
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              >
                <b>La mejor opción para tu salud bucal</b>
              </Typography>
              <Typography
                variant="h3"
                component="h1"
                color="primary"
                align="center"
                p={2}
                sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              >
                <b>La mejor opción para tu salud bucal</b>
              </Typography>
              <Typography
                variant="h4"
                component="h2"
                pl={5}
                pr={5}
                pt={2}
                color="secondary"
                align="center"
                sx={{ display: { xs: "none", sm: "none", md: "block" } }}
              >
                ¡Ven y descubre lo que podemos hacer por tu sonrisa!
              </Typography>
              <Typography
                variant="h6"
                component="h2"
                pl={5}
                pr={5}
                pt={2}
                color="secondary"
                align="center"
                sx={{ display: { xs: "block", sm: "block", md: "none" } }}
              >
                ¡Ven y descubre lo que podemos hacer por tu sonrisa!
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={1} md={12}>
          <ServiciosMain />
        </Grid>
        <Box
          sx={{
            width: "100%",
            maxWidth: "l",
          }}
          pb={2}
        >
          <Map />
        </Box>
      </Grid>
    </>
  );
};

export default Inicio;
