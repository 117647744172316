import React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { IconButton, Typography } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import HomeIcon from "@mui/icons-material/Home";
import InfoIcon from "@mui/icons-material/Info";
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import { NavLink as RouterLink } from "react-router-dom";
import BgImage from "../components/css/FondoSmall.png";

const styles = {
  Bg: {
    backgroundImage: `url(${BgImage})`,
    backgroundColor: "FFF",
    backgroundRepeat: "repeat",
    backgroundSize: "100px 100px",
  },
};

export default function SideMenu() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{
        width: 200,
        zIndex: 3,
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
      style={styles.Bg}
    >
      <List>
        <ListItem button component={RouterLink} to={"/"}>
          <ListItemIcon>
            <HomeIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary="Inicio"
            primaryTypographyProps={{
              color: "secondary",
              fontWeight: "medium",
            }}
            sx={{ marginLeft: "-20px" }}
          />
        </ListItem>
        <ListItem button component={RouterLink} to={"/servicios"}>
          <ListItemIcon>
            <LocalHospitalIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary="Servicios"
            primaryTypographyProps={{
              color: "secondary",
              fontWeight: "medium",
            }}
            sx={{ marginLeft: "-20px" }}
          />
        </ListItem>
        <ListItem button component={RouterLink} to={"/nosotros"}>
          <ListItemIcon>
            <InfoIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary="Nosotros"
            primaryTypographyProps={{
              color: "secondary",
              fontWeight: "medium",
            }}
            sx={{ marginLeft: "-20px" }}
          />
        </ListItem>
      </List>
      <Divider />
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          justifyContent: "flex-end",
        }}
      >
        <img alt="Logo" src="/static/Logo.svg" className="logoSide" />
      </Box>
      <Box>
        <Typography align="center" variant="caption" mb={2} color="primary">
          <p>Hecho con &#128151; para Linda</p>
        </Typography>
      </Box>
    </Box>
  );

  return (
    <div>
      {["right"].map((anchor) => (
        <React.Fragment key={anchor}>
          <IconButton
            size="large"
            aria-label="menu"
            sx={{
              display: { s: "block", md: "none" },
            }}
            onClick={toggleDrawer(anchor, true)}
            color="secondary"
          >
            <MenuIcon fontSize="inherit" />
          </IconButton>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
