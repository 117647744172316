import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";

import { NavLink as RouterLink } from "react-router-dom";

const LogoHeader = () => {
  return (
    <Button variant="text" component={RouterLink} to="/" sx={{}}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div>
          <img
            alt="Logo"
            src="/static/Logo.svg"
            className="logo"
            height={100}
          />
        </div>
        <div
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            color="white"
            variant="h6"
            component="h1"
            ml={2}
            sx={{ textAlign: "left" }}
          >
            Consultorio Dental <br /> MyA
          </Typography>
        </div>
      </Box>
    </Button>
  );
};

export default LogoHeader;
