import { Typography } from "@mui/material";
import { Card } from "@mui/material";
import React from "react";
import "./css/Map.css";

const Map = () => {
  return (
    <>
      <Typography variant="h3" color="primary" align="center" mb={3} mt={3}>
        Ubicación
      </Typography>

      <Card variant="outlined" sx={{ height: 500 }}>
        <iframe
          className="Map"
          loading="lazy"
          frameBorder="0"
          scrolling="no"
          maptypecontrol="no"
          zoomcontrol="false"
          src="https://maps.google.com/maps?q=Puerto%20escondido%20310%2C%20san%20jer%C3%B3nimo%20Chicahualco&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
          title="Consultorio dental MYA"
          aria-label="Consultorio dental MYA"
        ></iframe>
      </Card>
    </>
  );
};

export default Map;
