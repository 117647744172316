import React from "react";
import { Container } from "@mui/material";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./themeConfig";
import { Navbar } from "./components/Navbar";
import Footer from "./components/Footer";
import Inicio from "./components/Inicio";
import Servicios from "./components/Servicios";
import Nosotros from "./components/Nosotros";
import NotFound from "./components/NotFound";
import BgImage from "./components/css/FondoSmall.png";
import ScrollToTop from "./components/utils/ScrollToTop";

function App() {
  const styles = {
    BgContainer: {
      backgroundImage: `url(${BgImage})`,
      backgroundColor: "FFF",
      backgroundRepeat: "repeat",
      backgroundSize: "100px 100px",
    },
  };

  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <ScrollToTop />
        <div className="App" style={styles.BgContainer}>
          <Navbar />
          <Container
            maxWidth="xl"
            sx={{
              overflowX: "hidden",
            }}
          >
            <Routes>
              <Route path="/" element={<Inicio />} />
              <Route path="/servicios" element={<Servicios />} />
              <Route path="/nosotros" element={<Nosotros />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Container>
          <Footer />
        </div>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
