import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Grid,
  Box,
} from "@mui/material";
import servicios from "../resources/servicios";

function SimpleDialog(props) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose();
  };

  let servicioDetalle = selectedValue;

  return (
    <Dialog onClose={handleClose} open={open} minWidth={800}>
      <DialogTitle>
        <Typography variant="h5" color="primary.dark">
          {servicioDetalle.nombre}
        </Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 12,
              top: 12,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box
            mb={1}
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={servicioDetalle.imagen}
              alt={servicioDetalle.nombre}
              className="detailImg"
            />
          </Box>
          <Box>
            <DialogContentText mb={1}>
              <Typography variant="body1" color="secondary.main" p={0}>
                <b> {servicioDetalle.descripcionLarga}</b>
              </Typography>
            </DialogContentText>
            <DialogContentText>
              <b>Costo: </b> ${servicioDetalle.costo}
            </DialogContentText>
            <DialogContentText>
              <b>Duración:</b> {servicioDetalle.duracion}
            </DialogContentText>
            <br />
            <DialogContentText>
              <b>Antes de este tratamiento:</b> <br />{" "}
              {servicioDetalle.recAntes}
            </DialogContentText>
            <DialogContentText>
              <b>Después de este tratamiento:</b> <br />{" "}
              {servicioDetalle.recDespues}
            </DialogContentText>
          </Box>
        </Box>
      </DialogContent>

      <DialogActions
        sx={{
          display: "flex",
          justifyContent: "center",
        }}
        mb={2}
      >
        <Button
          mb={2}
          onClick={handleClose}
          variant="contained"
          autoFocus
          color="secondary"
          href="tel:7229322216"
          sx={{
            maxWidth: "140px",
            textAlign: "center",
            marginBottom: "1rem",
          }}
        >
          Hacer una cita
        </Button>
      </DialogActions>
    </Dialog>
  );
}

SimpleDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.object.isRequired,
};

export default function SimpleDialogDemo() {
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setselectedValue] = React.useState({});

  const handleService = (servicio) => {
    setselectedValue(servicio);
    console.log(servicio);
    handleClickOpen();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
      <>
        <Typography variant="h3" color="primary" align="center" mb={3} mt={3}>
          Nuestros servicios
        </Typography>
        <Box sx={{ maxWidth: "lg", mx: "auto" }} mb={3}>
          <Grid
            container
            spacing={{ xs: 2, sm: 4, md: 6 }}
            columns={{ xs: 1, sm: 2, md: 12 }}
            sx={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            {servicios.map((servicio, index) => (
              <Grid
                item
                key={index}
                xs={1}
                sm={1}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  sx={{
                    maxWidth: "100%",
                    minWidth: "100%",
                    "&:hover": {
                      transition: "0.2s",
                      bgcolor: "primary.main",
                      color: "white",
                    },
                  }}
                  variant="outlined"
                >
                  <CardActionArea
                    sx={{}}
                    onClick={() => handleService(servicio)}
                  >
                    <CardMedia
                      component="img"
                      /* height="200" */
                      image={servicio.imagen}
                      alt={servicio.nombre}
                      sx={{
                        minHeight: 250,
                        maxHeight: 250,
                      }}
                    />
                    <CardContent>
                      <Typography gutterBottom variant="h5" component="div">
                        {servicio.nombre}
                      </Typography>
                      <Typography variant="body2">
                        {servicio.descripcion}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Box>
      </>
    </div>
  );
}
