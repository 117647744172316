import { Card, CardContent, Typography, Grid, Box, Link } from "@mui/material";
import "./css/Map.css";
import React from "react";

const Nosotros = () => {
  return (
    <Box pb={4}>
      <Typography variant="h3" color="primary" align="center" mb={3} mt={3}>
        Acerca de nosotros
      </Typography>
      <Box sx={{ maxWidth: "lg", mx: "auto" }} pb={2}>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
          columns={{ xs: 1, sm: 1, md: 10 }}
          sx={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Grid
            item
            xs={1}
            sm={1}
            md={3}
            sx={{ display: "flex", justifyContent: "center" }}
            mb={{ xs: 1, sm: 1, md: 3 }}
            mt={{ xs: 1, sm: 1, md: 3 }}
          >
            <Card
              sx={{
                maxWidth: 350,
                minWidth: 300,
                minHeight: 200,
                color: "secondary.main",
                "&:hover": {
                  transition: "0.2s",
                  bgcolor: "primary.main",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              <CardContent>
                <Typography gutterBottom variant="h3" align="center" sx={{}}>
                  Horario
                </Typography>
                <Typography variant="body1" align="center">
                  <b>Lunes - Viernes</b>
                </Typography>
                <Typography variant="body1" align="center">
                  10:00 am - 2:00pm <br /> 4:00 pm - 6:00pm
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={3}
            sx={{ display: "flex", justifyContent: "center" }}
            mb={{ xs: 1, sm: 1, md: 3 }}
            mt={{ xs: 1, sm: 1, md: 3 }}
          >
            <Card
              sx={{
                maxWidth: 350,
                minWidth: 300,
                minHeight: 200,
                color: "secondary.main",
                "&:hover": {
                  transition: "0.2s",
                  bgcolor: "primary.main",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              <CardContent>
                <Typography gutterBottom variant="h3" align="center" sx={{}}>
                  Ubicación
                </Typography>
                <Typography variant="body1" align="center">
                  Puerto Escondido #310 <br /> San Jerónimo Chicahualco <br />
                  Metepec, Edo. Mex.
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid
            item
            xs={1}
            sm={1}
            md={3}
            sx={{ display: "flex", justifyContent: "center" }}
            mb={{ xs: 1, sm: 1, md: 3 }}
            mt={{ xs: 1, sm: 1, md: 3 }}
          >
            <Card
              sx={{
                maxWidth: 350,
                minWidth: 300,
                minHeight: 200,
                color: "secondary.main",
                "&:hover": {
                  transition: "0.2s",
                  bgcolor: "primary.main",
                  color: "white",
                },
              }}
              variant="outlined"
            >
              <CardContent>
                <Typography gutterBottom variant="h3" align="center" sx={{}}>
                  Contacto
                </Typography>
                <Typography variant="body1" align="center">
                  Teléfono:{" "}
                  <Link
                    color="info.dark"
                    href="tel:7229322216"
                    underline="hover"
                    sx={{}}
                  >
                    <b>728-135-0247</b>
                  </Link>
                </Typography>
                <Typography variant="body1" align="center">
                  E-mail:{" "}
                  <Link
                    color="info.dark"
                    href="mailto:info@cdmya.com"
                    target="blank"
                    underline="hover"
                  >
                    <b>info@cdmya.com</b>
                  </Link>
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>
      <Card variant="outlined" sx={{ height: 550 }}>
        <iframe
          className="Map"
          loading="lazy"
          frameBorder="0"
          scrolling="no"
          maptypecontrol="no"
          zoomcontrol="false"
          src="https://maps.google.com/maps?q=Puerto%20escondido%20310%2C%20san%20jer%C3%B3nimo%20Chicahualco&amp;t=m&amp;z=15&amp;output=embed&amp;iwloc=near"
          title="Consultorio dental MYA"
          aria-label="Consultorio dental MYA"
        ></iframe>
      </Card>
    </Box>
  );
};

export default Nosotros;
