import { Typography, Box } from "@mui/material";
import React from "react";

const NotFound = () => {
  return (
    <Box mt={10} mb={10}>
      <Box sx={{ maxWidth: "lg", mx: "auto" }}>
        <Typography variant="h3" color="secondary" align="center" mb={3} mt={3}>
          ¡Ups!
        </Typography>
        <Typography variant="h3" color="primary" align="center" mb={3} mt={3}>
          La página que buscas no existe.
        </Typography>
      </Box>
    </Box>
  );
};

export default NotFound;
