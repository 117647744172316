import { Button, Fab } from "@mui/material";
import { AppBar, Toolbar, styled } from "@mui/material";
import React from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Box from "@mui/material/Box";
import SideMenu from "./SideMenu";
import { NavLink as RouterLink } from "react-router-dom";
import LogoHeader from "./Header/LogoHeader";

const Offset = styled("div")(({ theme }) => theme.mixins.toolbar);

export const Navbar = () => {
  const color = "#ea4335";

  return (
    <>
      <AppBar sx={{ minHeight: 80 }}>
        <Toolbar
          sx={{
            margin: "auto 0",
            display: "flex",
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <LogoHeader />
          </Box>

          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Button
              color="secondary"
              size="large"
              sx={{ marginRight: "1rem" }}
              component={RouterLink}
              to="/"
            >
              <b>Inicio</b>
            </Button>

            <Button
              color="secondary"
              size="large"
              sx={{ marginRight: "1rem" }}
              component={RouterLink}
              to="/servicios"
            >
              <b>Servicios</b>
            </Button>

            <Button
              color="secondary"
              size="large"
              sx={{ marginRight: "1rem" }}
              component={RouterLink}
              to="/nosotros"
            >
              <b>Nosotros</b>
            </Button>
          </Box>
          <Box sx={{ display: { xs: "none", md: "block" } }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              startIcon={<PhoneIcon />}
              href="tel:7229322216"
            >
              728-135-0247
            </Button>
          </Box>
          <SideMenu />
        </Toolbar>
      </AppBar>
      <Offset
        sx={{
          "@media all": {
            minHeight: 80,
          },
        }}
      />
      <Fab
        color="primary"
        aria-label="call"
        sx={{
          position: "fixed",
          bottom: "40px",
          right: "30px",
          display: { s: "block", md: "none" },
          zIndex: 2,
        }}
        href="tel:7229322216"
      >
        <PhoneIcon sx={{ color: "white" }} />
      </Fab>
      <Fab
        aria-label="location"
        sx={{
          position: "fixed",
          bottom: "110px",
          right: "30px",
          display: { s: "block", md: "none" },
          zIndex: 2,
          bgcolor: color,
        }}
        href="httpS://maps.app.goo.gl/QHbB44Pa6DQaLjPu6"
      >
        <LocationOnIcon sx={{ color: "#fff" }} />
      </Fab>
    </>
  );
};
