const servicios = [
  {
    nombre: "Cita de primera vez",
    descripcion: "Elaboración de expediente y presupuesto",
    descripcionLarga:
      "Revisión del paciente, elaboración de expediente y presupuesto de su tratamiento.",
    imagen: "/static/Cita.jpg",
    costo: 300,
    duracion: "45 - 60 min.",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Continuar con la indicaciones provistas.",
  },
  {
    nombre: "Limpieza dental",
    descripcion: "Remoción de placa dentobacteriana",
    descripcionLarga:
      "Remoción de la placa adherida a las estructuras dentales.",
    imagen: "/static/limpieza.jpg",
    costo: 400,
    duracion: "2 citas ~ 30 min. cada una",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
  {
    nombre: "Resinas",
    descripcion: "Eliminación de caries y reconstrucción",
    descripcionLarga:
      "Eliminación de caries y reconstrucción con resina fotopolimerizable",
    imagen: "/static/resina.jpg",
    costo: "450 por resina",
    duracion: "45 - 60 min.",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
  {
    nombre: "Prótesis",
    descripcion: "Elaboración de prótesis dentales",
    descripcionLarga: "Reposición de piezas dentales ausentes.",
    imagen: "/static/Protesis.jpg",
    costo: "- Variable",
    duracion: "4 citas ~ 30 - 40 min. cada una",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal y limpieza de prótesis.",
  },
  {
    nombre: "Extracciones",
    descripcion: "Remoción de piezas dentales no funcionales",
    descripcionLarga: "Remoción de piezas dentales que perdieron su función",
    imagen: "/static/extra.jpg",
    costo: 400,
    duracion: "45 - 60 min.",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
  {
    nombre: "Endodoncias",
    descripcion: "Amputación del nervio para preservar pieza dental",
    descripcionLarga:
      "Amputación del nervio y restauración de la pieza dental.",
    imagen: "/static/endo.jpg",
    costo: 3000,
    duracion: "2 horas",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Acudir a citas de revisión y restauración",
  },
  {
    nombre: "Blanqueamientos",
    descripcion: "Reducción de manchas dentales",
    descripcionLarga:
      "Brinda una tonalidad más blanca y brillante a los dientes.",
    imagen: "/static/Blanqueamientos.jpg",
    costo: 300,
    duracion: "1 a 2 citas",
    recAntes: "Lavarse los dientes",
    recDespues: "Continuar con la higiene",
  },
  {
    nombre: "Curetaje",
    descripcion: "Remoción de sarro con ultrasonido",
    descripcionLarga: "Remoción de sarro con ultrasonido",
    imagen: "/static/curetaje.jpg",
    costo: 900,
    duracion: "2 - 3 citas ~ 45 - 60 min. cada una",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
  {
    nombre: "Cirugía de tercer molar",
    descripcion: "Extracción de tercer molar (Muela del juicio)",
    descripcionLarga: "Extracción de tercer molar (Muela del juicio)",
    imagen: "/static/tercer.jpg",
    costo: 2000,
    duracion: "1 - 2 horas",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
  {
    nombre: "Ortodoncia",
    descripcion: "Correción de desviaciones dentales",
    descripcionLarga:
      "Uso de aparatos ortodónticos para corregir posiciones dentales",
    imagen: "/static/Ortodoncia.jpg",
    costo: "- Variable",
    duracion: "Variable",
    recAntes: "Realizar un cepillado previo.",
    recDespues: "Mantener la higiene bucal.",
  },
];

export default servicios;
