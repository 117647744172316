import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#30D1A1",
      light: "#71ffd0",
      dark: "#009f72",
      contrastText: "#000",
    },
    secondary: {
      main: "#008776",
      light: "#4db7a5",
      dark: "#00594a",
      contrastText: "#FFF",
    },
    info: {
      main: "#005687",
      light: "#4db7a5",
      dark: "#00594a",
      contrastText: "#FFF",
    },
  },
  typography: {
    fontFamily: `"Exo 2", "sans-serif"`,
  },
});

export default theme;
